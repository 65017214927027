import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import CustomLoader from "../shared-components/Loader";
import { customeRoutes } from "./lazy-load";
import { LEFT_NAVIGATION_PAGE_URL } from "../models/navigation.models";
import InvalidUrl from "../shared-components/invalid-url/InvalidUrl";
import { FeatureFlag } from "../provider/flagFeature";
var Navigation = function (props) {
    var location = useLocation();
    var _a = useState(false), invalidUrl = _a[0], setInvalidUrl = _a[1];
    var featureData = useContext(FeatureFlag);
    var featureFlag = featureData.FlagFeaturesData;
    // this code will be removed when we add the permissions
    useEffect(function () {
        var validPaths = Object.values(LEFT_NAVIGATION_PAGE_URL);
        setInvalidUrl(validateUrl(validPaths));
    }, [location]);
    var validateUrl = function (validPaths) {
        var _a;
        if ((_a = location === null || location === void 0 ? void 0 : location.pathname) === null || _a === void 0 ? void 0 : _a.length) {
            var path = location.pathname.split("/")[1];
            return validPaths.indexOf("/" + path) === -1;
        }
    };
    return (_jsx("div", { className: "btapp-container container-fluid", children: _jsx(Suspense, { fallback: _jsx(CustomLoader, {}), children: featureFlag.BTAppFeature && !invalidUrl ? (_jsxs(Routes, { children: [_jsx(Route, { path: LEFT_NAVIGATION_PAGE_URL.BALLOTS, element: _jsx(customeRoutes.Ballots, { ballotStatusOption: props.ballotStatusOption, quickSearchSelectedUser: props.quickSearchSelectedUser, updateQuickSearchUser: props.updateQuickSearchUser, currentAction: props.currentAction, setCurrentAction: props.setCurrentAction, setIsUserOfficer: props.setIsUserOfficer, isUserOfficer: props.isUserOfficer, setGlobalSearchReset: props.setGlobalSearchReset, searchFlow: props.searchFlow }) }), _jsx(Route, { path: LEFT_NAVIGATION_PAGE_URL.DASHBOARD, element: _jsx(customeRoutes.DashBoard, {}) }), _jsx(Route, { path: LEFT_NAVIGATION_PAGE_URL.BALLOTDETAILS + "/:ballotId", element: _jsx(customeRoutes.BallotDetails, {}) }), _jsx(Route, { path: LEFT_NAVIGATION_PAGE_URL.BALLOTDETAILSADDITEMS + "/:ballotId", element: _jsx(customeRoutes.BallotDetailsAddItems, { committeeData: props.committeeData }) }), _jsx(Route, { path: LEFT_NAVIGATION_PAGE_URL.WORKITEM, element: _jsx(customeRoutes.WorkItem, {}) }), _jsx(Route, { path: LEFT_NAVIGATION_PAGE_URL.WORKITEM_AUDITLOG, element: _jsx(customeRoutes.WorkItemAudigLog, {}) }), _jsx(Route, { path: LEFT_NAVIGATION_PAGE_URL.WORKITEM, element: _jsx(customeRoutes.WorkItem, { currentAction: props.currentAction, setCurrentAction: props.setCurrentAction, setGlobalSearchReset: props.setGlobalSearchReset }) }), _jsx(Route, { path: LEFT_NAVIGATION_PAGE_URL.WORKITEMDETAIL + "/:workItemNumber", element: _jsx(customeRoutes.WorkItemDetail, {}) }), _jsx(Route, { path: LEFT_NAVIGATION_PAGE_URL.DTNDETAIL + "/:workItemNumber", element: _jsx(customeRoutes.DTNDetail, {}) }), _jsx(Route, { path: "/", element: _jsx(_Fragment, {}) }), _jsx(Route, { path: "*", element: _jsx(customeRoutes.Invalid, {}) })] })) : (_jsx(InvalidUrl, {})) }) }));
};
export default Navigation;
