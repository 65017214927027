var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from "react";
import { isReactAppAuthorized, cacheManager, clearAzureAdAuthCodeAction, encrypt } from "../helpers/util-common";
import { SHOW_NOT_FOUND_PAGE, HIDE_NOT_FOUND_PAGE } from "../action-type";
import { useDispatch, useSelector } from "react-redux";
import { validateNavigationFromUrl } from "../helpers/util-common";
import { getActiveCommitteesAction, getAllBallotStatusAction, getBallotDocumentsListAction } from "../redux/action/ballots";
import "./_layoutScreen.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { FeatureFlag } from "../provider/flagFeature";
import { isEmpty, orderBy, uniq } from "lodash";
import HeaderMenu from "../shared-components/Header/header";
import Breadcrumb from "../shared-components/breadcrumb";
import Navigation from "../navigation/navigation";
import * as commonActions from "../common.actions";
import { getWorkItemListAction } from "../redux/action/workItem";
import { LEFT_NAVIGATION_PAGE_URL } from "../models/navigation.models";
export var Layout = function () {
    var _a;
    var history = useLocation();
    var navigate = useNavigate();
    var featureData = (_a = useContext(FeatureFlag)) === null || _a === void 0 ? void 0 : _a.FlagFeaturesData;
    var dispatch = useDispatch();
    var _b = useState([]), committeeData = _b[0], setCommitteeData = _b[1];
    var _c = useState(''), currentAction = _c[0], setCurrentAction = _c[1];
    var _d = useState([]), ballotDesignationData = _d[0], setBallotDesignationData = _d[1];
    var _e = useState([{ text: "All", value: "All", key: "all" }]), ballotStatusOption = _e[0], setBallotStatusOption = _e[1];
    var _f = useState(false), resetClicked = _f[0], setResetClicked = _f[1];
    var workLitemResponse = useSelector(function (state) { return state.commonReducer.workItemList; });
    var _g = useState([]), committeeList = _g[0], setCommitteeList = _g[1];
    var _h = useState(""), searchText = _h[0], setSearchText = _h[1];
    var _j = useState(""), searchFlow = _j[0], setSearchFlow = _j[1];
    var isUserOfficer = useSelector(function (state) { return state.commonReducer.isUserOfficer; });
    //  this will be required in future
    // const [showAccessDenied, setShowAccessDenied] = useState(false);
    useEffect(function () {
        getCommitteeList();
        getAllBallotStatus();
        if (!searchText) {
            loadBallotData();
        }
    }, []);
    useEffect(function () {
        var workItemReqData = {
            "pageNumber": 1,
            "pageSize": 10,
            "sortColumnWithOrder": {
                "ModifiedDate": 2
            },
            "UserEmail": ""
        };
        if (!searchText) {
            isUserOfficer == false && getWorkItemListAction(dispatch, workItemReqData, function (response) {
            });
        }
    }, [isUserOfficer]);
    // This code will be used when the permissions are added in the code
    var validateBreadcrumbsUrls = function () {
        var currentPath = history.pathname;
        // Get URL Details
        if (currentPath == "/" && isReactAppAuthorized()) {
            window.history.pushState({}, "", "/ballot");
            window.location.reload();
        }
        var urlDetails = validateNavigationFromUrl(currentPath);
        if (!urlDetails) {
            dispatch({ type: SHOW_NOT_FOUND_PAGE }); // IF Routes Not Matched
        }
        else {
            dispatch({ type: HIDE_NOT_FOUND_PAGE });
        }
    };
    useEffect(function () {
        validateBreadcrumbsUrls();
    }, [window.location.pathname]);
    var props = useSelector(function (state) {
        return {
            currentLocation: state.commonReducer.currentRequest,
            documentStatusByCategory: state.commonReducer.documentStatusByCategory,
            showNotFoundPage: state.commonReducer.showNotFoundPage,
            ballotAdvanceSearchFormData: state.commonReducer.ballotAdvanceSearchFormData,
            quickSearchSelectedUser: state.commonReducer.quickSearchSelectedUser,
            isUserOfficer: state.commonReducer.isUserOfficer,
            wiExtraField: state.commonReducer.WorkItemExtraFields.filter(Boolean)
        };
    });
    var isUserAuthorized = isReactAppAuthorized();
    var getCommitteeList = function () {
        getActiveCommitteesAction(dispatch, function (response) {
            if (response && response.length > 0) {
                var CommitteeList = uniq(response.map(function (item) { return item.CommitteeDesignation; }));
                setCommitteeList(response);
                setCommitteeData(CommitteeList);
            }
        });
    };
    var loadBallotData = function () {
        var data = {
            pageNumber: 1,
            pageSize: -1,
            sortColumnWithOrder: { ModifiedDate: 2 },
        };
        getBallotDocumentsListAction(dispatch, data, function (response) {
            if (!isEmpty(response)) {
                var sortedBallotDesignationData = orderBy(response.Data, ["BallotDesignation"], ["asc"]);
                var ballotDesignationList = uniq(sortedBallotDesignationData.map(function (item) { return item.BallotDesignation; }));
                setBallotDesignationData(ballotDesignationList);
            }
        });
    };
    var onGlobalSearchSubmit = function (currentSearchOption, currentSearchText) {
        var _a;
        setSearchText(currentSearchText);
        setSearchFlow(currentSearchOption);
        if (!currentSearchText) {
            navigate(currentSearchOption);
        }
        var data = {
            "pageNumber": 1,
            "pageSize": ((_a = workLitemResponse.paginationModel) === null || _a === void 0 ? void 0 : _a.PageSize) || 10,
            "sortColumnWithOrder": {
                "ModifiedDate": 2,
            },
            "UserEmail": props.quickSearchSelectedUser ? props.quickSearchSelectedUser : "",
            "WorkItemGlobalSearch": currentSearchText,
            "ExtraFields": props.wiExtraField
        };
        if (currentSearchOption == "workitem") {
            getWorkItemListAction(dispatch, data, function (Result) {
                if (!currentSearchText || !(Result === null || Result === void 0 ? void 0 : Result.Data)) {
                    return;
                }
                var wkNumber = currentSearchText.match(/\d+/);
                wkNumber = wkNumber ? wkNumber[0] : null;
                var workItem = Result.Data.find(function (item) { return item.DraftCount === 0 && (item.WorkItemNumber === wkNumber); });
                var draftTrackingNumber = Result.Data.find(function (item) { return item.DraftCount === 1 && Result.TotalRecords === 1; });
                if (workItem) {
                    var workItemCode = encrypt("".concat(workItem.WorkItemNumber));
                    navigate("".concat(LEFT_NAVIGATION_PAGE_URL.WORKITEMDETAIL, "/").concat(workItemCode));
                }
                else if (draftTrackingNumber) {
                    var dtnParam = "".concat(draftTrackingNumber.WorkItemNumber, "/").concat(currentSearchText);
                    var dtnCode = encrypt(dtnParam);
                    navigate("".concat(LEFT_NAVIGATION_PAGE_URL.DTNDETAIL, "/").concat(dtnCode));
                }
                else {
                    navigate(currentSearchOption);
                }
            });
        }
        else {
            navigate(currentSearchOption);
        }
        setCurrentAction('global');
        dispatch(commonActions.setGlobalSearchText(currentSearchText));
    };
    var onAdvanceSearchSubmit = function (formData, type) {
        if (window.location.pathname !== "/ballot") {
            navigate("/ballot");
        }
        var selectedBallotStatus = (formData === null || formData === void 0 ? void 0 : formData.selectedBallotStatus) || "All";
        setCurrentAction('advance');
        dispatch(commonActions.setQuickSearchSelectedBallotStatus(selectedBallotStatus));
        dispatch(commonActions.setBallotAdvanceSearch(formData));
    };
    var setAdvBallotSearchFormData = function (formData) {
        dispatch(commonActions.setAdvBallotSearchFormData(formData));
    };
    var userDetail = cacheManager.getItem("userDetail") && JSON.parse(cacheManager.getItem("userDetail"));
    if (!isUserAuthorized && !userDetail) {
        setTimeout(function () {
            window.location.assign(clearAzureAdAuthCodeAction());
        }, 500);
    }
    var getAllBallotStatus = function () {
        getAllBallotStatusAction(dispatch, function (response) {
            if (response && response.length > 0) {
                var mappedOptions = response.map(function (item) { return ({
                    text: item.BallotStatus,
                    value: item.BallotStatus.toString(),
                    key: item.BallotStatus.toLowerCase(),
                }); });
                setBallotStatusOption(__spreadArray([{ text: "All", value: "All", key: "all" }], mappedOptions, true));
            }
        });
    };
    var updateQuickSearchUser = function () {
        dispatch(commonActions.setQuickSearchSelectedUser("akumar"));
    };
    var setIsUserOfficer = function (flag) {
        dispatch(commonActions.setIsUserOfficer(flag));
    };
    var setGlobalSearchReset = function () {
        setResetClicked(true);
        dispatch(commonActions.setGlobalSearchText(""));
    };
    return (_jsx(_Fragment, { children: isUserAuthorized ? (_jsxs("div", { className: "homePage", "data-testid": "layout_id", children: [userDetail && _jsx(HeaderMenu, __assign({}, props, { committeeData: committeeData, ballotDesignationData: ballotDesignationData, userDetail: userDetail, onGlobalSearchSubmit: onGlobalSearchSubmit, onAdvanceSearchSubmit: onAdvanceSearchSubmit, setAdvBallotSearchFormData: setAdvBallotSearchFormData, ballotStatusOption: ballotStatusOption, resetClicked: resetClicked, setResetClicked: setResetClicked })), _jsxs(_Fragment, { children: [_jsx(Breadcrumb, {}), _jsx(Navigation, { isUserAuthorized: isUserAuthorized, ballotStatusOption: ballotStatusOption, quickSearchSelectedUser: props.quickSearchSelectedUser, updateQuickSearchUser: updateQuickSearchUser, currentAction: currentAction, setCurrentAction: setCurrentAction, setIsUserOfficer: setIsUserOfficer, committeeData: committeeList, isUserOfficer: props.isUserOfficer, setGlobalSearchReset: setGlobalSearchReset, searchFlow: searchFlow })] })] })) : ("") }));
};
export default Layout;
